import React, { useState } from "react";
import Seo from "../../components/seo";
// import "../../styles/_qa_special_page.scss";
import "../../styles/main.scss";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { Link } from "gatsby";
import HeroSecQA from "../../images/qa-service/Hero-section-qa.svg";
import SedinLogoRound from "../../images/cad-service/sedin-round.svg";
import SedinLogoText from "../../images/cad-service/sedin-text.svg";
import Tick from "../../images/qa-service/tick.svg";
import Arrow from "../../images/qa-service/arrow.svg";
import Sparkle from "../../images/qa-service/sparkle.svg";
import clinicalmind from "../../images/qa-service/clinicalMind.png";
import enphase from "../../images/qa-service/enphase.png";
import ilife from "../../images/qa-service/ilife.png";
import pricebook from "../../images/qa-service/pricebook.png";
import tunecore from "../../images/qa-service/tunecore.png";
// import rewa from "../../images/qa-service/rewa.png";
import RewaNew from "../../images/qa-service/rewa-white.svg";
import Star from "../../images/qa-service/star.svg";
import Connection from "../../images/qa-service/connection.svg";
import Prefernces from "../../images/qa-service/prefernces.svg";
import ReliableTesting from "../../images/qa-service/reliable-testing.svg";
import ShipFaster from "../../images/qa-service/ship-faster.svg";
import HighTestCoverage from "../../images/qa-service/hightestCoverage.svg";
import Market from "../../images/qa-service/marketing-readiness.svg";
import Tools from "../../images/qa-service/tools.svg";
import ArrowRoundFull from "../../images/qa-service/jump_arrow.png";
import QaArrow from "../../images/qa-service/qa-arrow.svg";
import DownArrow from "../../images/qa-service/downArrow.png";
import ClientsBackground from "../../images/qa-service/Clients.png";
import Time from "../../images/qa-service/time.svg";
import TestingService from "../../images/qa-service/testing-services.svg";
import handShake from "../../images/qa-service/handshake.svg";
import caseStudies from "../../images/qa-service/casse-studies.svg";
import RighBottomArrow from "../../images/qa-service/RighBottomArrow.svg";
import FooterArrow from "../../images/qa-service/footer-Arrow.svg";
import CylinderImg from "../../images/qa-service/Cylinder.svg";
import ClientRedImg from "../../images/qa-service/client-red-img.svg";
import { StaticImage } from "gatsby-plugin-image";
import DownArrowCasetudies from "../../images/qa-service/CaseStudyDownArrow.svg";
import SwiperCaseComponent from "../../components/CaseStudiesSlider";
import ClientSlider from "../../components/ClientsSlider";
import QAtoolsAndTechnologies from "../../components/QA-tools";
import { CSSTransition } from "react-transition-group";
import QAserviceForm from "../../components/QA-ServiceForm";
import QAEamilBlock from "../../components/QA-EmailBlock";

const QaServicePage = () => {
    const [showModal, setShowModal] = useState(false);
    const handleModal = () => {
        setShowModal(true);
    };
    return (
        <>
            <Seo />
            <section className="qa-header">
                <div className="container">
                    <div className="site-logo">
                        <Link to="/" className="logo-inner">
                            <img src={SedinLogoRound} alt="sedin-logo" loading="lazy" />
                            <img
                                src={SedinLogoText}
                                alt="sedin-logo"
                                loading="lazy"
                                className="sedin-logo-text"
                            />
                        </Link>
                        <div className="flex flex-ai-c contact-us-qa">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_1_2509)">
                                    <path
                                        d="M18.6797 4.6875C20.2699 5.11427 21.7198 5.95178 22.884 7.11599C24.0482 8.2802 24.8857 9.73014 25.3125 11.3203"
                                        stroke="white"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M17.707 8.30859C18.6634 8.56259 19.5357 9.0649 20.2354 9.76462C20.9351 10.4643 21.4374 11.3366 21.6914 12.293"
                                        stroke="white"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M10.8398 14.625C11.8046 16.6172 13.4155 18.224 15.4102 19.1836C15.5573 19.2533 15.72 19.2834 15.8824 19.2711C16.0447 19.2588 16.201 19.2044 16.3359 19.1133L19.2656 17.1563C19.3951 17.0685 19.5446 17.0149 19.7004 17.0006C19.8561 16.9862 20.013 17.0116 20.1563 17.0742L25.6406 19.4297C25.8281 19.5077 25.9847 19.6452 26.0863 19.8209C26.1879 19.9967 26.229 20.201 26.2031 20.4024C26.0293 21.7591 25.3671 23.006 24.3404 23.9097C23.3137 24.8135 21.9928 25.3122 20.625 25.3125C16.3981 25.3125 12.3443 23.6334 9.35549 20.6445C6.36663 17.6557 4.6875 13.6019 4.6875 9.37501C4.68781 8.00719 5.1865 6.68635 6.09026 5.65964C6.99403 4.63293 8.24093 3.97071 9.59766 3.79688C9.79903 3.77102 10.0033 3.81206 10.1791 3.9137C10.3548 4.01534 10.4923 4.17194 10.5703 4.35938L12.9258 9.85548C12.987 9.99651 13.0127 10.1504 13.0004 10.3037C12.9881 10.457 12.9384 10.6049 12.8555 10.7344L10.8984 13.7109C10.8113 13.8456 10.7602 14.0003 10.7499 14.1603C10.7397 14.3204 10.7706 14.4804 10.8398 14.625Z"
                                        stroke="white"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1_2509">
                                        <rect width="30" height="30" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <a className="contact" href="tel: +1 415-903-3658">
                                Call Us: +1 415-903-3658
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="qa-service-hero-sec">
                <div className="container">
                    <div className="qa-service-hero-sec-grid">
                        <div className="qa-service-hero-sec-grid__left">
                            <div className="sparkle">
                                <img src={Sparkle} alt="tick" loading="lazy" class="sparkle-img" />
                            </div>
                            {/* <h1 className="text-fw-medium gap-05x qa-h1">
                                Faster Time-to-Market Powered by Automated <br />
                                <span className="qa-service">QA Services</span>
                            </h1> */}
                            <h1 className="text-fw-medium gap-05x qa-h1">
                                Release faster, with confidence Your Trusted QA outsourcing partner
                            </h1>

                            <div className="qa-services-wrapper">
                                <div className="flex flex-ai-c ">
                                    <img src={Tick} alt="tick" loading="lazy" class="tick" />
                                    <p className="service-item">
                                        Automated end-to-end testing done for you
                                    </p>
                                </div>
                                <div className="flex ai-c jc-s">
                                    <img src={Tick} alt="tick" loading="lazy" class="tick" />
                                    <p className="service-item">
                                        Apt bug reporting by non flaky automation
                                    </p>
                                </div>
                                <div className="flex ai-c jc-s">
                                    <img src={Tick} alt="tick" loading="lazy" class="tick" />
                                    <p className="service-item">Integrate with your CI/CD</p>
                                </div>
                                <div className="talk-to-us-wrapper">
                                    <div className="talk-support">
                                        <button className="talk-btn" onClick={handleModal}>
                                            Talk to QA Experts
                                        </button>
                                        <p className="support">
                                            <span>24*7 Support</span>
                                            <span className="horizontal-bar">|</span>
                                            <span>Dedicated Team</span>
                                        </p>
                                    </div>
                                    <div className="get-quote-container">
                                        <span className="get-quote">Get Quote</span>
                                        <img
                                            src={Arrow}
                                            alt="arrow"
                                            loading="lazy"
                                            className="arrow"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="qa-service-hero-sec-grid__right">
                            <img
                                // src="../../images/qa-service/DashboardRighGrid.png"
                                src={HeroSecQA}
                                alt="dashboard-arrow"
                                class="chart-img-dashboard"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="trusted-teams">
                <div className="container container--mid-v2">
                    <p className="heading gap-1x">Trusted by the highest performance teams at</p>
                    <div className="org-list">
                        <img
                            src={clinicalmind}
                            alt="clinicalMind"
                            loading="lazy"
                            class="sparkle-img"
                        />
                        <img src={ilife} alt="ilife" loading="lazy" class="sparkle-img" />
                        <img src={tunecore} alt="tunecore" loading="lazy" class="sparkle-img" />
                        <img src={enphase} alt="enphase" loading="lazy" class="sparkle-img" />
                        <img src={RewaNew} alt="rewa" loading="lazy" class="sparkle-img" />
                        <img src={pricebook} alt="pricebook" loading="lazy" class="sparkle-img" />
                    </div>
                    <div class="dashed-line-horizontal">
                        <img src={Star} alt="star" loading="lazy" class="star-img" />
                    </div>
                    <div className="quotes-wrapper">
                        <h2 className="quotes gap-3x">
                            Maximize test coverage and ship faster always for high performing and
                            quality products
                        </h2>
                    </div>
                    <div className="clients-swiper-wrapper">
                        <ClientSlider />
                        <div className="client-background-image">
                            <img src={ClientsBackground} alt="" />
                        </div>
                        <div className="client-red-img">
                            <img src={ClientRedImg} alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="market-readiness">
                <div className="container container--py-v2">
                    <div className="sub-head-wrapper">
                        <img
                            src={Market}
                            alt="background"
                            loading="lazy"
                            className="readiness-icon"
                        />
                        <span className="sub-head">Market readiness</span>
                    </div>
                    <h2 className="heading">
                        Ensure Your Next Release is Always Ready to
                        <span className="head-colour-text">Go-to-market</span>
                        <img src={CylinderImg} alt="qa-img" className="cylinder-img" />
                    </h2>
                    <div className="content">
                        <p className="para gap-05x">
                            Our QA services guarantee high test coverage and exceptional quality
                            with a minimal number of testers.
                        </p>
                        <p className="para">
                            Our in-house experts in the USA, Canada, and AU provide round the clock
                            bug investigation, test creation, and maintenance, ensuring your
                            software is flawless and ready for deployment.
                        </p>
                    </div>
                    <div class="background-container">
                        <img
                            src={Connection}
                            alt="background"
                            loading="lazy"
                            class="background-image"
                        />
                        <div class="grid-container">
                            <div class="grid-item active">
                                <img src={Prefernces} alt="background" loading="lazy" />
                                <span>Speed Up Development</span>
                            </div>
                            <div class="grid-item">
                                <img src={ReliableTesting} alt="background" loading="lazy" />
                                <span>Reliable Testing</span>
                            </div>
                            <div class="grid-item">
                                <img src={HighTestCoverage} alt="background" loading="lazy" />
                                <span>High Test Coverage</span>
                            </div>
                            <div class="grid-item">
                                <img src={ShipFaster} alt="background" loading="lazy" />
                                <span> Ship Faster to PROD</span>
                            </div>
                        </div>
                    </div>
                    <div class="dashed-line-horizontal black-horizontal">
                        <img src={Star} alt="tick" loading="lazy" class="star-img" />
                    </div>
                </div>
            </section>

            <section className="tools-technologies">
                <div className="container container--mid-v2">
                    <div className="sub-head-wrapper">
                        <img
                            src={Tools}
                            alt="background"
                            loading="lazy"
                            className="readiness-icon"
                        />
                        <span className="sub-head">Tools & Technologies</span>
                    </div>
                    <h2 className="heading gap-2x">
                        We integrate with the <span className="head-colour-text">Tools</span> that
                        you already use!
                    </h2>
                    <div className="tools-sec-main">
                        <QAtoolsAndTechnologies />
                    </div>
                </div>
            </section>

            <section className="qa-process">
                <div className="container container--mid-v2">
                    <div className="qa-relative">
                        <div className="qa-arrow">
                            <img src={QaArrow} alt="QaArrow" />
                        </div>
                    </div>
                    <div className="qa-container">
                        <span className="sub-head">How we work</span>
                        <h2 className="heading">
                            Say Goodbye to Software Glitches with{" "}
                            <span className="head-colour-text">Our Easy QA Process</span>{" "}
                        </h2>

                        <div class="background-container">
                            <div className="static-image">
                                <img src={ArrowRoundFull} alt="background" />
                            </div>
                            <div class="grid-container">
                                <div class="grid-item">
                                    <span className="step">Step 1</span>
                                    <h4 className="heading">Initiate Three-Weeks Free Trial</h4>
                                    <p className="para">
                                        Experience effortless QA with our no-obligation three-week
                                        free trial. We’ll develop and maintain your test suite,
                                        requiring a product tour and environment setup.
                                    </p>
                                </div>
                                <div class="grid-item">
                                    <span className="step">Step 2</span>
                                    <h4 className="heading">Audit Product Testing Process</h4>
                                    <p className="para">
                                        Guarantee your product's excellence with our thorough audit.
                                        We use QA tools to uncover gaps & offer actionable steps to
                                        enhance the flexibility of your test code.
                                    </p>
                                </div>
                                <div class="grid-item">
                                    <span className="step">Step 3</span>
                                    <h4 className="heading">Start Your Path to Success</h4>
                                    <p className="para">
                                        Embark on your hassle-free QA journey with Sedin. Our team
                                        will set up everything, providing you with a clear action
                                        plan to optimize your testing strategy.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="dashed-line-horizontal">
                            <button className="dashed-button" onClick={handleModal}>
                                Start a Three Week Risk-free Trial
                            </button>
                        </div>
                        <p className="dashed-support">
                            <span>GET COURSE CORRECTION ACTION PLAN</span>
                        </p>
                    </div>
                </div>
            </section>

            <section className="engagement-model">
                <div className="container container--mid-v2">
                    <div className="model-container">
                        <div className="sub-head-wrapper">
                            <img
                                src={handShake}
                                alt="background"
                                loading="lazy"
                                className="engagement-icon"
                            />
                            <span className="sub-head">Engagement Model</span>
                        </div>
                        <div className="flexible-text-content">
                            <p className="flexible-text">
                                <span>Flexible Model Tailored To Your Requirements</span>
                            </p>
                        </div>
                        <div className="grid-container">
                            <div className="grid">
                                <div className="grid-image">
                                    <img src={Time} alt="Time" />
                                </div>
                                <h2 className="grid-heading">Time and Material</h2>
                                <p className="grid-content">
                                    We’ll completely manage your resources on an hourly basis,
                                    giving you control and flexibility.
                                </p>
                            </div>
                            <div className="center-arrow">
                                <img className="Downarrow" src={DownArrow} alt="Down Arrow" />
                            </div>
                            <div className="grid">
                                <div className="grid-image">
                                    <img src={TestingService} alt="TestingService" />
                                </div>
                                <h2 className="grid-heading">Managed Testing service</h2>
                                <p className="grid-content">
                                    We take full ownership of the project and deliver from in-house,
                                    ensuring seamless and efficient QA processes.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="dashed-line-horizontal black-horizontal">
                        <button className="engagement-dashed-button" onClick={handleModal}>
                            Talk to QA Experts
                        </button>
                    </div>
                </div>
            </section>

            <section className="case-study">
                <div className="container container--last">
                    <div className="case-studies-container">
                        <div className="relative-bottom-arrow">
                            <div className="right-bottom-arrow">
                                <img src={RighBottomArrow} alt="RighBottomArrow" />
                            </div>
                        </div>
                        <div className="head-wrapper">
                            <div className="sub-head-wrapper">
                                <img
                                    src={caseStudies}
                                    alt="background"
                                    loading="lazy"
                                    className="case-study-icon"
                                />
                                <span className="sub-head">Case Studies</span>
                            </div>
                            <div className="flex flex-ac-i flex-jc-c">
                                <h2 className="heading">
                                    Your<span className="head-colour-text">success story</span>could
                                    be next!
                                </h2>
                            </div>
                        </div>
                        <div className="slider-wrapper">
                            <div className="case-slider-wrapper">
                                <SwiperCaseComponent />
                            </div>
                            <div className="downarrow-image">
                                <img
                                    src={DownArrowCasetudies}
                                    alt="DownArrow-logo"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* model form sec  */}
            <CSSTransition in={showModal} timeout={300} classNames="fade" unmountOnExit>
                <QAserviceForm showModal={showModal} setShowModal={setShowModal} footer />
            </CSSTransition>
            <section className="qa-footer">
                <div className="container">
                    <div className="flex flex-ac-i flex-jc-c">
                        <p className="transform-text">
                            Ready to transform your software delivery process?
                        </p>
                    </div>
                    <div class="flex-container">
                        <div class="input-text-container">
                            {/* <input
                                type="text"
                                class="text-email-box"
                                placeholder="Enter your Email"
                            />
                            <button type="submit" class="text-talk-btn">
                                Talk to Us
                            </button> */}
                            <QAEamilBlock
                                FooterCallToAction
                                background="f8f9fa"
                                title="Talk to Us"
                            />
                            <div className="start-here-wrapper">
                                <span className="start-here-text">Start your Journey</span>
                                <div className="arrow-img">
                                    <img
                                        src={FooterArrow}
                                        alt="arrow"
                                        loading="lazy"
                                        className="start-here-arrow"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="white-line"></section>

            <section className="qa-footer-b">
                <div className="container">
                    <div className="footer-wrapper">
                        <p className="footer-text">© 2024 Sedin Technologies Inc.</p>
                        <a className="footer-link" href="/terms-and-conditions">
                            Terms and Conditions
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
};

export default QaServicePage;
