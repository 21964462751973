import React from "react";
import { useState, useRef } from "react";
import { navigate } from "gatsby";
import Spinner from "./Spinner";
import axios from "axios";
import { StaticImage } from "gatsby-plugin-image";
import closeIcon from "../images/close.svg";
import ClientRedImg from "../images/qa-service/qa-red-icon-form.svg";

const QAserviceForm = ({ showModal, setShowModal, sedStart }) => {
    // const formAPIURL = `${process.env.GATSBY_QA_SERVICES_PAGE_FORM_URL}`;
    const formAPIURL = sedStart
        ? `${process.env.GATSBY_CONTACT_FORM_URL}`
        : `${process.env.GATSBY_QA_SERVICES_PAGE_FORM_URL}`;

    const [formSubmitError, setSubmitError] = useState("");
    const [isLoading, setLoading] = useState(false);
    const formEl = useRef(null);

    const [stepForm, setFormState] = useState({
        name: "",
        email: "",
        message: "",
    });
    const [isSubmitted, setSubmit] = useState(false);
    const [validationErrors, setValidationError] = useState({
        nameError: "",
        emailError: "",
    });

    function inputsHandler(e) {
        setFormState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }

    function formValidator() {
        let nameError = "";
        let emailError = "";
        if (!/^[a-zA-Z ]+$/.test(stepForm.name)) nameError = "Invalid Name";

        if (
            !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                stepForm.email
            )
        )
            emailError = "Invalid Email";

        if (!stepForm.name) nameError = "Name is required";
        if (!stepForm.email) emailError = "Email is required";

        if (nameError || emailError) {
            setValidationError({ nameError, emailError });
            return false;
        }
        return true;
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        setSubmit(true);
        const isValid = formValidator();
        if (isValid) {
            setLoading(true);
            // reset validation errors
            setValidationError({
                nameError: "",
                emailError: "",
            });
            setSubmitError("");
            const formdata = stepForm;
            axios
                .post(formAPIURL, formdata, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((resp) => {
                    setSubmit(false);
                    setFormState({
                        name: "",
                        email: "",
                        message: "",
                    });
                    setLoading(false);
                    navigate("/contact/thank-you/");
                })
                .catch((err) => {
                    setSubmit(false);
                    setLoading(false);
                    setSubmitError("Oops, Something went wrong. Please try again");
                });
            // console.log(formdata);
        }
    }

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className="qa-wrapper-maximo">
                <div className="modal-container">
                    <div className="lead-modal-container">
                        <img
                            src={closeIcon}
                            className="close-icon"
                            onClick={handleClose}
                            alt="close-icon"
                        />
                        <img src={ClientRedImg} alt="red-img" className="red-img-form" />

                        <div className="lead-content">
                            <h1 className="lead-heading">Let's talk</h1>
                            <p className="lead-para">
                                Please fill out the form and we will get back to you shortly.
                            </p>
                        </div>
                        <div className="lead-modal">
                            <div>
                                <form
                                    className="landing-form"
                                    onSubmit={(e) => handleFormSubmit(e)}
                                    ref={formEl}
                                    noValidate
                                    autoComplete="off"
                                >
                                    {/* <div className="text-p1 header text-clr-primary gap-05x text-fw-medium">
                                        Fill out the form and we will contact you shortly
                                    </div> */}
                                    <div className="input-row-wrap gap-2x">
                                        <div className="input-row">
                                            <div className="mat-group">
                                                <input
                                                    type="text"
                                                    className={`${
                                                        validationErrors.nameError && isSubmitted
                                                            ? "error"
                                                            : ""
                                                    }`}
                                                    name="name"
                                                    required
                                                    autoComplete="off"
                                                    onChange={(e) => inputsHandler(e)}
                                                    value={stepForm.name}
                                                />
                                                <span className="highlight"></span>
                                                <span className="bar"></span>
                                                <label htmlFor="name">Name*</label>
                                                <div className="error-resp">
                                                    {validationErrors.nameError}
                                                </div>
                                            </div>
                                            <div className="mat-group">
                                                <input
                                                    type="text"
                                                    className={`${
                                                        validationErrors.emailError && isSubmitted
                                                            ? "error"
                                                            : ""
                                                    }`}
                                                    name="email"
                                                    onChange={(e) => inputsHandler(e)}
                                                    required
                                                    autoComplete="off"
                                                    value={stepForm.email}
                                                />
                                                <span className="highlight"></span>
                                                <span className="bar"></span>
                                                <label htmlFor="email">Business Email*</label>
                                                <div className="error-resp">
                                                    {validationErrors.emailError}
                                                </div>
                                            </div>

                                            {sedStart ? (
                                                <div className="mat-group">
                                                    <textarea
                                                        rows={5}
                                                        type="text"
                                                        name="message"
                                                        required
                                                        onChange={(e) => inputsHandler(e)}
                                                        value={stepForm.message}
                                                    />
                                                    <span className="highlight"></span>
                                                    <span className="bar"></span>
                                                    <label htmlFor="name">Message</label>
                                                    <div className="error-resp"></div>
                                                </div>
                                            ) : (
                                                <div className="mat-group">
                                                    <textarea
                                                        rows={5}
                                                        type="text"
                                                        name="message"
                                                        required
                                                        onChange={(e) => inputsHandler(e)}
                                                        value={stepForm.message}
                                                    />
                                                    <span className="highlight"></span>
                                                    <span className="bar"></span>
                                                    <label htmlFor="name">
                                                        Describe your project requirements
                                                    </label>
                                                    <div className="error-resp"></div>
                                                </div>
                                            )}
                                            {/* <div className="mat-group ">
                                                <div className="terms-condition-block">
                                                    <input
                                                        type="checkbox"
                                                        name="terms"
                                                        required="required"
                                                        onChange={(e) => inputsHandler(e)}
                                                        className="terms-conditions"
                                                    />
                                                    <span className="terms-conditions-text text-p4 text-clr-secondary text-fw-regular">
                                                        "I agree to share my information with Sedin
                                                        and understand it will be used as described
                                                        in their Privacy Policy."
                                                    </span>
                                                </div>
                                                <div className="error-resp">
                                                    {validationErrors.termsError}
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <button className="sdn-cta-btn qa-btn" type="submit">
                                        <span className="text-fw-bold">
                                            Let’s discuss your requirements
                                        </span>
                                        <Spinner loading={isLoading} />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QAserviceForm;
