import React from "react";
import { useState } from "react";
import axios from "axios";
import { navigate } from "gatsby";

const QAEamilBlock = ({ FooterCallToAction, background, title, salesforce }) => {
    const formAPIURL = `${process.env.GATSBY_QA_SERVICES_PAGE_FORM_URL}`;

    const [isSubmitted, setSubmit] = useState(false);
    const [emailValue, setEmailValue] = useState("");
    const [validationErrors, setValidationError] = useState("");
    const [formSubmitError, setSubmitError] = useState("");

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (emailValue === "") {
            setValidationError("Email is required");
        } else if (
            !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                emailValue
            )
        ) {
            setValidationError("Invalid Email");
        } else if (
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                emailValue
            ) &&
            emailValue !== ""
        ) {
            setValidationError("");
            setSubmitError("");
            const formdata = emailValue;
            axios
                .post(formAPIURL, formdata, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((resp) => {
                    setSubmit(false);
                    setEmailValue("");
                    navigate("/contact/thank-you/");
                })
                .catch((err) => {
                    setSubmit(false);
                    setSubmitError("Oops, Something went wrong. Please try again");
                });
        }
    };
    return (
        <>
            <div className="qa-email-wrapper">
                <div
                    className="qa-email-field flex flex-ai-c"
                    // style={
                    //     salesforce && {
                    //         // background: FooterCallToAction ? "white" : "#f8f9fa",
                    //         borderRadius: 0,
                    //     }
                    // }
                >
                    <form noValidate autoComplete="off" onSubmit={(e) => handleFormSubmit(e)}>
                        <input
                            type="text"
                            placeholder="Business email"
                            name="email"
                            autoComplete="off"
                            required
                            onChange={(e) => setEmailValue(e.target.value)}
                            className="text-p4 text-fw-regular text-secondary-clr"
                            style={{
                                background: background,
                            }}
                        />
                        <button
                            className="campaign-cta-btn salesforce-btn"
                            style={{ color: "white", fontWeight: "600" }}
                        >
                            {title}
                        </button>
                    </form>
                </div>
                <div className="form-error">{formSubmitError}</div>
                <div className="error-resp">{validationErrors}</div>
            </div>
        </>
    );
};

export default QAEamilBlock;
